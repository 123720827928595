<template>
  <header class="section is-header is-padding-top-safe">
    <div class="inner is-padding-right-sm is-padding-left-md">
      <div class="grid is-middle is-between">
          <div class="column">
            <div class="box is-flex is-bottom">
                <div class="box is-margin-right-sm">
                  <router-link to="/" class="site-logo-link">
                    <img src="/img/logo.svg" width="200" alt="logo">
                  </router-link>
                </div>
            </div>
          </div>
      </div>
    </div>
    <div class="open"> 
      <span class="cls"></span> 
      <span></span>
      <span class="cls"></span> 
    </div>
    <!-- ここから追加 -->
    <div class="overlay"></div>
    <div class="menu-center">
      <!-- メニューの内容 -->
      <ul class="sub-menu ">
        <li><router-link class="text is-link-reverse" to="/">Home</router-link></li>
        <li><router-link class="text is-link-reverse" to="/springwater">全国湧き水マップ</router-link></li>
        <li><router-link class="text is-link-reverse" to="/hikyoekirank">秘境駅ランキング</router-link></li>
        <li><router-link class="text is-link-reverse" to="/plateau-aqicn">きれいな高原</router-link></li>
        <li><router-link class="text is-link-reverse" to="/big-tree">全国巨樹マップ</router-link></li>
        <li><router-link class="text is-link-reverse" to="/onsen">全国温泉マップ</router-link></li>
        <li><router-link class="text is-link-reverse" to="/mountain">全国山岳標高マップ</router-link></li>
        <li><router-link class="text is-link-reverse" to="/japanese-ceramics">全国陶磁器産地マップ</router-link></li>
        <li><router-link class="text is-link-reverse" to="/three-prefecture-border">全国三県境マップ</router-link></li>
        <li><a href="https://sentan.tabi2.lol/" target="_blank" class="text is-link-reverse">先端発見！<i class="material-icons is-fit">open_in_new</i></a></li>
        <li><a href="https://note.com/livlog_llc/m/m5e2bc5e2a64e" target="_blank" class="text is-link-reverse">Blog<i class="material-icons is-fit">open_in_new</i></a></li>
      </ul>
      <!-- その他のメニュー項目 -->
    </div>
  </header>
</template>

<script>
export default{
  data() {
    return {

    }
  },
  mounted() {
    $(document).ready(function () {
      // オープンボタンのクリックイベント
      $(document).delegate('.open', 'click', function () {
        $(this).addClass('oppenned');
        $('.overlay, .menu-center').show(); // レイヤーとメニューの表示切替
      });

      // オープン状態のボタンのクリックイベント
      $(document).delegate('.oppenned', 'click', function () {
        $(this).removeClass('oppenned');
        $('.overlay, .menu-center').hide(); // レイヤーとメニューの非表示
      });

      // メニュー内のリンクがクリックされた時のイベント
      $('.menu-center a, .menu-center router-link').on('click', function () {
        $('.overlay, .menu-center').hide(); // レイヤーとメニューの非表示
        $('.open').removeClass('oppenned'); // 「open」ボタンの状態をリセット
      });
    });
  }
}
</script>
