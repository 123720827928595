<template>
  <footer class="section is-footer is-padding-bottom-safe">
    <div class="inner is-padding-horizontal-md">
      <div class="box is-space-md">
        <div class="box">
          <div class="grid is-center is-gap-sm">
            <div class="column">
              <router-link to="/springwater" class="text is-link-reverse link-bk-dark" >全国湧き水マップ</router-link> |
            </div>
            <div class="column">
              <router-link to="/hikyoekirank" class="text is-link-reverse link-bk-dark" >秘境駅ランキング</router-link> |
            </div>
            <div class="column">
              <router-link to="/plateau-aqicn" class="text is-link-reverse link-bk-dark" >きれいな高原</router-link> |
            </div>
            <div class="column">
              <router-link to="/big-tree" class="text is-link-reverse link-bk-dark" >全国巨樹マップ</router-link> |
            </div>
            <div class="column">
              <router-link to="/onsen" class="text is-link-reverse link-bk-dark" >全国温泉マップ</router-link> |
            </div>
            <div class="column">
              <router-link to="/mountain" class="text is-link-reverse link-bk-dark" >全国山岳標高マップ</router-link> |
            </div>
            <div class="column">
              <router-link to="/japanese-ceramics" class="text is-link-reverse link-bk-dark" >全国陶磁器産地マップ</router-link> |
            </div>
            <div class="column">
              <router-link to="/three-prefecture-border" class="text is-link-reverse link-bk-dark" >全国三県境マップ</router-link> |
            </div>
            <div class="column">
              <a class="text is-link-reverse link-bk-dark" href="https://sentan.tabi2.lol/" target="_blank">先端発見！<i class="material-icons is-fit">open_in_new</i></a> |
            </div>
            <div class="column">
              <a class="text is-link-reverse link-bk-dark" href="https://note.com/livlog_llc/m/m5e2bc5e2a64e" target="_blank">Blog<i class="material-icons is-fit">open_in_new</i></a> |
            </div>
            <div class="column">
              <a class="text is-link-reverse link-bk-dark" href="https://v2.tabi2.lol">新デザイン</a>
            </div>
          </div>
        </div>
        <div class="box">
            <p class="text is-center is-sm">
              <span class="text is-font-sans-en">© 2021-2024</span>
              &nbsp;
              <a class="text is-link-reverse link-bk-dark" href="https://livlog.jp/" rel="noopener noreferrer" target="_blank">
                <span class="text is-font-sans-en">LivLog llc.</span>
              </a>
              &nbsp;
              All rights reserved.
            </p>
        </div>
      </div>
    </div>
    <div id="backToTop" class="fixed">
      <a href="#"></a>
    </div>
  </footer>
</template>
<script>
export default{
  data() {
    return {

    }
  },
  mounted() {
    $(document).ready(function() {
      var pagetop = $('#backToTop');
      // ボタン非表示
      pagetop.hide();
      // 100px スクロールしたらボタン表示
      $(window).scroll(function () {
         if ($(this).scrollTop() > 100) {
              pagetop.fadeIn();
         } else {
              pagetop.fadeOut();
         }
      });
      pagetop.click(function () {
         $('body, html').animate({ scrollTop: 0 }, 500);
         return false;
      });
    });
  }
}
</script>